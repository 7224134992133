import { useFormModalStore } from "@/store/modals/formModalStore.ts";
import { Button } from "@/components/ui/button.tsx";
import Group from "../Group";
import { trackEvent } from "@/lib/utils/tracking";

interface FormButtonProps {
  hasPartnership?: boolean;
  clickedProvider?: string;
}

const FormButton = ({ hasPartnership, clickedProvider }: FormButtonProps) => {
  const { open, setHasPartnership, setClickedProvider } = useFormModalStore();

  return (
    <Button
      onClick={() => {
        trackEvent("get_offer_clicked", {
          placement: "form_button",
        });

        setHasPartnership(hasPartnership);
        setClickedProvider(clickedProvider);
        open();
      }}
      className={"w-full bg-gradient-to-l from-fuchsia-400 to-fuchsia-500"}
    >
      <Group w={"full"} justify={"center"}>
        <span>Få et uforpliktende tilbud</span>
      </Group>
    </Button>
  );
};

export default FormButton;
